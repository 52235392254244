import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const Text = ({
  description,
  componentId,
  handleAnswers,
  answer,
  disabled,
  obligatory,
}) => {
  const handleChoice = (choice) => {
    const answerData = {
      componentId: componentId,
      answer: choice,
      obligatory: obligatory,
    };
    handleAnswers(answerData);
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 6,
        mx: "5%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>{description}</Typography>
    </Box>
  );
};

export default Text;
