import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { handleCreateErrors } from "../../../components/utils/errors"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "Promotions";

  const [selectedtypology_id, setSelectedtypology_id] = useState(null);
  const promotion_typologiesTemp = useGetUnlimitedListOf("Promotion_Typologies", "", "list");
  const promotion_typologiesData = promotion_typologiesTemp.listElements;

  const columns = ["id", "typology_id", "title", "description", "start", "end"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (formData.typology_id === null || !formData.title?.trim()) {
    window.alert("typology_id, title non possono essere vuoti!");
    return;
  }

formData.start = dayjs(formData.start).format("YYYY-MM-DD HH:mm")
formData.end = dayjs(formData.end).format("YYYY-MM-DD HH:mm")

	 const errors = {}
	 const apiCall = `/${table}/create`
    try {
      const response = await axiosPrivate.post(apiCall, formData);
      if (!response) throw new Error("Errore nella creazione");

      window.alert("Creazione avvenuta con successo")

    } catch (error) {
    errors.call = apiCall;
    errors.callData = formData;
    errors.error = error;
    errors.forceReload = false;

    handleCreateErrors(errors, true)

    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo typology_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={promotion_typologiesData}
              getOptionLabel={(option) => option.id}
              value={
                selectedtypology_id
                  ? promotion_typologiesData.find((promotion) => promotion.id === selectedtypology_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedtypology_id(newValue.id);
                  setValue("typology_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select typology_id"
                  placeholder="typology_id"
                  error={!!errors.typology_id}
                  helperText={
                    errors.typology_id ? "Il campo typology_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="id" && column !== "typology_id" && column !== "start" && column !== "end")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}

{/* datetimepicker per campo start */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="start"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DateTimePicker
					sx={{m:1}}
                  label="start"
                  inputFormat="DD/MM/YYYY"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "50%", m: 1 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>

{/* datetimepicker per campo end */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="end"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DateTimePicker
					sx={{m:1}}
                  label="end"
                  inputFormat="DD/MM/YYYY"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "50%", m: 1 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;