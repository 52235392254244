import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { handleCreateErrors } from "../../../components/utils/errors"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "User_Tokens";

  const [selecteduser_id, setSelecteduser_id] = useState(null);
  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const columns = ["id", "user_id", "key", "active", "expire"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (!formData.user_id?.trim() || !formData.key?.trim() || formData.expire === null) {
    window.alert("user_id, key, expire non possono essere vuoti!");
    return;
  }

formData.expire = dayjs(formData.expire).format("YYYY-MM-DD")

	 const errors = {}
	 const apiCall = `/${table}/create`
    try {
      const response = await axiosPrivate.post(apiCall, formData);
      if (!response) throw new Error("Errore nella creazione");

      window.alert("Creazione avvenuta con successo")

    } catch (error) {
    errors.call = apiCall;
    errors.callData = formData;
    errors.error = error;
    errors.forceReload = false;

    handleCreateErrors(errors, true)

    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo user_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id}
              value={
                selecteduser_id
                  ? usersData.find((user) => user.id === selecteduser_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_id(newValue.id);
                  setValue("user_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_id"
                  placeholder="user_id"
                  error={!!errors.user_id}
                  helperText={
                    errors.user_id ? "Il campo user_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="id" && column !== "user_id" && column !== "expire" && column !== "active")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}

{/* switch per campo active */}
<Controller
      name="active"
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>active:</Typography>
          <Switch color="primary" {...field} checked={field.value} />
        </Box>
      )}
    />

{/* datepicker per campo expire */}
<LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name="expire"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
					sx={{m:1}}
                  label="expire"
                  inputFormat="DD/MM/YYYY"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "50%", m: 1 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;