import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "Promotion_Assignments"
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "id");

  const [selecteduser_id, setSelecteduser_id] = useState();
  const [selectedpromotion_id, setSelectedpromotion_id] = useState();
  const [selectedassignment_state_id, setSelectedassignment_state_id] = useState();
  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const promotionsTemp = useGetUnlimitedListOf("Promotions", "", "list");
  const promotionsData = promotionsTemp.listElements;

  const assignment_statesTemp = useGetUnlimitedListOf("Assignment_States", "", "list");
  const assignment_statesData = assignment_statesTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.user_id) {
        setSelecteduser_id(prev.user_id);
        setValue("user_id", prev.user_id);
      }
      if(prev.promotion_id) {
        setSelectedpromotion_id(prev.promotion_id);
        setValue("promotion_id", prev.promotion_id);
      }
      if(prev.assignment_state_id) {
        setSelectedassignment_state_id(prev.assignment_state_id);
        setValue("assignment_state_id", prev.assignment_state_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.user_id?.trim() || !data.promotion_id?.trim() || !data.assignment_state_id?.trim()) {
    window.alert("user_id, promotion_id, assignment_state_id non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				id: prev.id,
        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo user_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selecteduser_id
                  ? usersData.find((user) => user.id === selecteduser_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_id(newValue.id);
                  setValue("user_id", newValue.id);
                } else {
                  setSelecteduser_id(null);
                  setValue("user_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_id"
                  placeholder="user_id"
                  error={!!errors.user_id}
                  helperText={
                    errors.user_id ? "Il campo user_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo promotion_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={promotionsData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedpromotion_id
                  ? promotionsData.find((promotion) => promotion.id === selectedpromotion_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpromotion_id(newValue.id);
                  setValue("promotion_id", newValue.id);
                } else {
                  setSelectedpromotion_id(null);
                  setValue("promotion_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select promotion_id"
                  placeholder="promotion_id"
                  error={!!errors.promotion_id}
                  helperText={
                    errors.promotion_id ? "Il campo promotion_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo assignment_state_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={assignment_statesData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedassignment_state_id
                  ? assignment_statesData.find((promotion) => promotion.id === selectedassignment_state_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedassignment_state_id(newValue.id);
                  setValue("assignment_state_id", newValue.id);
                } else {
                  setSelectedassignment_state_id(null);
                  setValue("assignment_state_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select assignment_state_id"
                  placeholder="assignment_state_id"
                  error={!!errors.assignment_state_id}
                  helperText={
                    errors.assignment_state_id ? "Il campo assignment_state_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "id" && key !== "user_id" && key !== "promotion_id" && key !== "assignment_state_id" && key !== "valid")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
{/* switch per campo valid */}
<Controller
      name="valid"
      control={control}
      defaultValue={prev.valid}
      render={({ field }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ mr: 2 }}>valid:</Typography>
          <Switch color="primary" {...field} checked={field.value} />
        </Box>
      )}
    />

            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;