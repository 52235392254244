
import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import Update from "./Update";
import Delete from "./Delete";
import Create from "./Create";
import NavBar from "../../../components/navbar/NavBar";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Cluster_Types_Table = ({roles}) => {
  const table = "Cluster_Types";
  const temp = useGetUnlimitedListOf(table, "", "list");
  const data = temp.listElements;

  const columns = [
    { field: "id", headerName: "id", flex: 1 },
    { field: "description", headerName: "description", flex: 1 },
    {
      field: "actions",
      headerName: "Azioni",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedRowId(params.row.id);
              handleOpen(true, "mod");
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
          {modOpen && selectedRowId === params.row.id && (
            <Update handleOpen={handleOpen} open={modOpen} id={selectedRowId} />
          )}
          <Delete id={params.row.id} />
        </>
      ),
    },
  ];

  const [modOpen, setModOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleOpen = (value, type) => {
    if (type === "mod") setModOpen(value);
    else setCreateOpen(value);
  };

  return (
    <>
      <NavBar roles={roles}/>
      <Typography variant="h4" sx={{ m: 3 }}>
        Tabella {table}
      </Typography>
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <Paper
          sx={{
            height: 400,
            width: "90%",
            overflow: "auto",
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
          />
        </Paper>
        <Button sx={{ mt: 3 }} onClick={() => handleOpen(true, "create")}>
          <Typography> Aggiungi un elemento alla tabella </Typography>
          <AddIcon color="primary" />
        </Button>
        {createOpen && (
          <Create columns={columns} handleOpen={handleOpen} open={createOpen} />
        )}
      </Box>
    </>
  );
};

export default Cluster_Types_Table;