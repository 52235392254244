import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { handleCreateErrors } from "../../../components/utils/errors"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";


const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "Cluster_Elements";

  const [selectedcluster_centroid_id, setSelectedcluster_centroid_id] = useState(null);
  const [selecteduser_id, setSelecteduser_id] = useState(null);
  const cluster_centroidsTemp = useGetUnlimitedListOf("Cluster_Centroids", "", "list");
  const cluster_centroidsData = cluster_centroidsTemp.listElements;

  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const columns = ["cluster_centroid_id", "user_id"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (formData.cluster_centroid_id === null || !formData.user_id?.trim()) {
    window.alert("cluster_centroid_id, user_id non possono essere vuoti!");
    return;
  }


	 const errors = {}
	 const apiCall = `/${table}/create`
    try {
      const response = await axiosPrivate.post(apiCall, formData);
      if (!response) throw new Error("Errore nella creazione");

      window.alert("Creazione avvenuta con successo")

    } catch (error) {
    errors.call = apiCall;
    errors.callData = formData;
    errors.error = error;
    errors.forceReload = false;

    handleCreateErrors(errors, true)

    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo cluster_centroid_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={cluster_centroidsData}
              getOptionLabel={(option) => option.id}
              value={
                selectedcluster_centroid_id
                  ? cluster_centroidsData.find((cluster) => cluster.id === selectedcluster_centroid_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedcluster_centroid_id(newValue.id);
                  setValue("cluster_centroid_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select cluster_centroid_id"
                  placeholder="cluster_centroid_id"
                  error={!!errors.cluster_centroid_id}
                  helperText={
                    errors.cluster_centroid_id ? "Il campo cluster_centroid_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo user_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id}
              value={
                selecteduser_id
                  ? usersData.find((user) => user.id === selecteduser_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_id(newValue.id);
                  setValue("user_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_id"
                  placeholder="user_id"
                  error={!!errors.user_id}
                  helperText={
                    errors.user_id ? "Il campo user_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="cluster_centroid_id" && column !== "user_id" && column !== "")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;