import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function createData(
  id,
  title,
  promotions_type,
  promotions_state,
  promotions_state_id
  //colonne tabella
) {
  return {
    id,
    title,
    promotions_type,
    promotions_state,
    promotions_state_id,
    //colonne tabella
  };
}

const PromotionAssignmentsList = (promotions, userType, frontendParams) => {
  const navigate = useNavigate();
  const [clickedIndex, setClickedIndex] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = (index) => {
    setOpen(true)
    setClickedIndex(index);
    setTimeout(() => setOpen(false), 3000);
  };

  const renderTableHeader = (header, title) => {
    if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "true"
    )
      return (
        <React.Fragment>
          <ArrowDropDownIcon />
          {title}
        </React.Fragment>
      );
    else if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "false"
    )
      return (
        <React.Fragment>
          <ArrowDropUpIcon />
          {title}
        </React.Fragment>
      );
  };

  const rows = [];

  for (let i = 0; i < promotions?.length; i++) {
    rows.push(
      createData(
        promotions[i]["promotion_assignments.promotion_id"],
        promotions[i]["promotions.title"],
        promotions[i]["promotion_typologies.description"],
        promotions[i]["assignment_states.description"],
        promotions[i]["promotion_assignments.assignment_state_id"]
      )
    );
  }

  const columns = [
    {
      field: "title",
      headerName: "Titolo",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{ textAlign: "left", justifyContent: "flex-start" }}
            onClick={() => navigate(`${params.row.id}`)}
          >
            {params.value}
          </Button>
        );
      },
    },
    // {
    //   field: "promotions_type",
    //   headerName: "Tipologia",
    //   flex: 2,
    //   sortable: false,
    //   renderHeader: (param) => {
    //     return renderTableHeader(param.field, param.colDef.headerName);
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <Button
    //         fullWidth
    //         size="large"
    //         sx={{ textAlign: "left", justifyContent: "flex-start" }}
    //         onClick={() => navigate(`${params.row.id}`)}
    //       >
    //         {params.value}
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "promotions_state",
      headerName: "Stato",
      maxWidth: 75,
      minWidth: 75,
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              userType === "Farmacia"
                ? params.row.promotions_state_id === 1
                  ? "Nuova non ancora inoltrata"
                  : params.row.promotions_state_id === 2
                  ? "Nuova"
                  : params.value
                : params.value
            }
            followCursor
            arrow
            open={clickedIndex === params.row.id && open ? true : false}
          >
            <Button
              fullWidth
              onClick={() => handleTooltipOpen(params.row.id)}
              sx={{
                mx: -1,
                height: "100%",
                color: "white",
                borderRadius: 0,
                background:
                  params.row.promotions_state_id === 1 ||
                  params.row.promotions_state_id === 2
                    ? "#42a5f5"
                    : params.row.promotions_state_id === 3 ||
                      params.row.promotions_state_id === 6
                    ? "#009534"
                    : params.row.promotions_state_id === 4
                    ? "#e01a1c"
                    : params.row.promotions_state_id === 5 && "red",
              }}
            >
              {params.row.promotions_state_id === 1 ||
              params.row.promotions_state_id === 2 ? (
                <NewReleasesIcon />
              ) : params.row.promotions_state_id === 3 ? (
                <CheckIcon />
              ) : params.row.promotions_state_id === 6 ? (
                <>
                  <CheckIcon />
                  <CameraAltIcon />
                </>
              ) : params.row.promotions_state_id === 4 ? (
                <CloseIcon />
              ) : (
                params.row.promotions_state_id === 5 && <AccessTimeIcon />
              )}
            </Button>
          </Tooltip>
        );
      },
    },
  ];
  return { rows: rows, columns: columns };
};

export default PromotionAssignmentsList;
