import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "User_Relationships"
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "");

  const [selectedparent_id, setSelectedparent_id] = useState();
  const [selectedson_id, setSelectedson_id] = useState();
  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.parent_id) {
        setSelectedparent_id(prev.parent_id);
        setValue("parent_id", prev.parent_id);
      }
      if(prev.son_id) {
        setSelectedson_id(prev.son_id);
        setValue("son_id", prev.son_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.parent_id?.trim() || !data.son_id?.trim()) {
    window.alert("parent_id, son_id non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {

        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo parent_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedparent_id
                  ? usersData.find((user) => user.id === selectedparent_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedparent_id(newValue.id);
                  setValue("parent_id", newValue.id);
                } else {
                  setSelectedparent_id(null);
                  setValue("parent_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select parent_id"
                  placeholder="parent_id"
                  error={!!errors.parent_id}
                  helperText={
                    errors.parent_id ? "Il campo parent_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo son_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedson_id
                  ? usersData.find((user) => user.id === selectedson_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedson_id(newValue.id);
                  setValue("son_id", newValue.id);
                } else {
                  setSelectedson_id(null);
                  setValue("son_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select son_id"
                  placeholder="son_id"
                  error={!!errors.son_id}
                  helperText={
                    errors.son_id ? "Il campo son_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "parent_id" && key !== "son_id")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;