import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "Chat_Messages"
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "chat_id");

  const [selectedchat_id, setSelectedchat_id] = useState();
  const [selectedpromotion_assignment_id, setSelectedpromotion_assignment_id] = useState();
  const chatsTemp = useGetUnlimitedListOf("Chats", "", "list");
  const chatsData = chatsTemp.listElements;

  const promotion_assignmentsTemp = useGetUnlimitedListOf("Promotion_Assignments", "", "list");
  const promotion_assignmentsData = promotion_assignmentsTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.chat_id) {
        setSelectedchat_id(prev.chat_id);
        setValue("chat_id", prev.chat_id);
      }
      if(prev.promotion_assignment_id) {
        setSelectedpromotion_assignment_id(prev.promotion_assignment_id);
        setValue("promotion_assignment_id", prev.promotion_assignment_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.promotion_assignment_id?.trim() || !data.text?.trim()) {
    window.alert("promotion_assignment_id, text non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				chat_id: prev.chat_id,
        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo chat_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={chatsData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedchat_id
                  ? chatsData.find((bot) => bot.id === selectedchat_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedchat_id(newValue.id);
                  setValue("chat_id", newValue.id);
                } else {
                  setSelectedchat_id(null);
                  setValue("chat_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select chat_id"
                  placeholder="chat_id"
                  error={!!errors.chat_id}
                  helperText={
                    errors.chat_id ? "Il campo chat_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo promotion_assignment_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={promotion_assignmentsData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedpromotion_assignment_id
                  ? promotion_assignmentsData.find((promotion) => promotion.id === selectedpromotion_assignment_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpromotion_assignment_id(newValue.id);
                  setValue("promotion_assignment_id", newValue.id);
                } else {
                  setSelectedpromotion_assignment_id(null);
                  setValue("promotion_assignment_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select promotion_assignment_id"
                  placeholder="promotion_assignment_id"
                  error={!!errors.promotion_assignment_id}
                  helperText={
                    errors.promotion_assignment_id ? "Il campo promotion_assignment_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "chat_id" && key !== "chat_id" && key !== "promotion_assignment_id")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;